import React from "react"
import {
  FaGithub,
  FaCodepen,
  FaLinkedinIn,
  FaMediumM,
  FaTwitter,
  FaSkype,
  FaStackOverflow
} from "react-icons/fa"

import "./title.css"

export const Title = ({ title, social, side, height }) => {
  return (
    <div
      style={{ height }}
      className={`title ${side} ${social ? "social" : ""}`}
    >
      {social && (
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/bdmostafa"
            >
              <FaGithub />
            </a>
          </li>
          {/* <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://codepen.io/"
            >
              <FaCodepen />
            </a>
          </li> */}
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stackoverflow.com/users/13206610/mostafa-bd"
            >
              <FaStackOverflow />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/md-mostafa/"
            >
              <FaLinkedinIn />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://md-mostafa.medium.com/"
            >
              <FaMediumM />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/md__mostafa"
            >
              <FaTwitter />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://join.skype.com/invite/NXefQumr6vOK"
            >
              <FaSkype />
            </a>
          </li>
        </ul>
      )}
      <p>{title}</p>
    </div>
  )
}

Title.defaultProps = {
  side: "left",
  height: "160px",
}
