import React from "react"
import { FaAngleUp, FaStar, FaCodeBranch } from "react-icons/fa"

import { Container } from "../Container/container"


import "./footer.css"

export const Footer = () => {
  const handleScroll = e => {
    e.preventDefault()
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }
  return (
    <footer className="footer-area">
      <Container padding="50px 25px">
        <div className="footer">
          <div className="copyright">
           
            <p>
            © Copyright {new Date().getFullYear()}.  Developed by <a 
            style={{ textDecoration: 'none', color: '#d8624c'}}
            href="htts://mdmostafa.com">Mostafa</a> {" "}
              <span className="mouseEvents" onClick={handleScroll}>
                <FaAngleUp />
              </span>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
