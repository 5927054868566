import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import { Header } from "../Header/header"
import { Footer } from "../Footer/footer"

import "./layout.css"

const Layout = ({ locationPath, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
console.log(locationPath)
  return (
    <>
      <Header locationPath={locationPath} siteTitle={data.site.siteMetadata.title} />
      <div style={{}}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
