import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FaBars } from "react-icons/fa"

import { Container } from "../Container/container"
import { Logo } from "../Logo"

import { StickyContainer, Sticky } from "react-sticky"

import "./header.css"

const Header = ({ locationPath }) => {
  const [mobileNav, setMobileNav] = useState(false)

  const handleScroll = e => {
    e.preventDefault()
    if (locationPath === "/") {
      const hash = e.target.hash
      const el = document.querySelector(hash)
      const offsetTop = el.offsetTop
      setMobileNav(false)
      if (typeof window !== `undefined`) {
        window.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        })
      }
    }
  }
  console.log(locationPath)
  return (
    <StickyContainer>
      {/* Other elements can be in between `StickyContainer` and `Sticky`,
        but certain styles can break the positioning logic used. */}
      <Sticky>
        {({
          style,

          // the following are also available but unused in this example
          isSticky,
          wasSticky,
          distanceFromTop,
          distanceFromBottom,
          calculatedHeight,
        }) => (
          <header className="header-bg" style={style}>
            <Container padding="25px 25px">
              <div className="header">
                <div className="logo">
                  <h1>
                    <Link to="/">
                      <Logo />
                    </Link>
                  </h1>
                </div>
                <div
                  onClick={() => setMobileNav(!mobileNav)}
                  className="mobile-nav mouseEvents"
                >
                  <FaBars />
                </div>
                <ul
                  style={{ backgroundColor: "rgb(216, 98, 76)" }}
                  className={`menubar ${mobileNav ? "mobilenav-activate" : ""}`}
                >
                  {locationPath === "/" ? (
                    <>
                      <li>
                        <a onClick={handleScroll} href="#about">
                          About
                        </a>
                      </li>
                      <li>
                        <a onClick={handleScroll} href="#skill">
                          Skill
                        </a>
                      </li>
                      <li>
                        <a onClick={handleScroll} href="#portfolio">
                          Portfolio
                        </a>
                      </li>
                      <li>
                        <a onClick={handleScroll} href="#blog">
                          Blog
                        </a>
                      </li>
                      <li>
                        <a onClick={handleScroll} href="#contact">
                          Contact
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      {/* <li>
                        <Link to="/resume">Resume</Link>
                      </li> */}
                      <li>
                        <Link to="/skills">Skills</Link>
                      </li>
                      <li>
                        <Link to="/portfolios">Projects</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/hireMe">Hire Me</Link>
                      </li>
                    </>
                  )}

                  <li>
                    <a
                      className="btn-download"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://drive.google.com/u/0/uc?id=1iyGLsJfu-imIgkdzgZq75pu9q3hNj6DW&export=download"
                    >
                      Resume
                    </a>
                  </li>
                </ul>
              </div>
            </Container>
          </header>
        )}
      </Sticky>
      {/* ... */}
    </StickyContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export { Header }
